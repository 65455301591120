export const TEST_LIST_URI = '/tests';
export const COPYRIGHT_URI = '/category/copyright';
export const EXERCISES_URI = '/exercises';
export const EXERCISES_LISTS_URI = '/exercises/lists';
export const TEST_URI = '/tests';
export const DOWNLOAD_PDF_URI = '/pdf';
export const DELETE_EXERCISE_URI = (exerciseId: number) => `${EXERCISES_URI}/${exerciseId}`;
export const TEST_URI_PDF = (testId: number | string) => `${TEST_URI}/${testId}/pdf`;
export const TEST_URI_ATTACHMENTS = (testId: number | string) => `${TEST_URI}/${testId}/attachments`;
export const TEST_URI_DELETE = (testId: number | string) => `${TEST_URI}/${testId}`;
export const TEST_URI_HASH = (testHash: string) => `${TEST_URI}/hash/${testHash}`;
export const TEST_URI_PREVIEW = (testHash: string) => `${TEST_URI}/${testHash}`;
export const TEST_URI_SAVE = `${TEST_URI}/create`;
export const TEST_URI_UPDATE = (id: number | undefined) => `${TEST_URI}/${id}`;
export const TEST_URI_ADD_GROUP = `${TEST_URI}/addGroup`;
export const TEST_URI_RECALCULATE = `${TEST_URI}/recalculate`;
export const FACET_URI = (categoryKey: string) => `/facets?categoryKey=${encodeURIComponent(categoryKey)}`;
