import { TreeNode } from '@/models/structure-mapping';
import { ExerciseAttribute } from '@/models';

export const getFlattenTree = (children: TreeNode[]) => {
  const flatChildren = [...children, ...children.map(item => item.children).flat()];
  return [...flatChildren, ...flatChildren.map(item => item?.children)].flat().filter(child => child);
};

export const handleParentNodes = (facets: TreeNode) => {
  facets.children?.forEach(child => {
    child.parentId = `${facets.key}-${facets.id}`;
    if (child.children) {
      handleParentNodes(child);
    }
  });
};

const updateChildren = (attributes: ExerciseAttribute, node: TreeNode, level: number) => {
  if (level > 1) {
    const nodeHasPositiveExercisesCount = Object.prototype.hasOwnProperty.call(attributes, node.key!) && Object.prototype.hasOwnProperty.call(attributes[node.key!], node.id!);
    node.numberOfExercises = nodeHasPositiveExercisesCount ? attributes[`${node.key}`][node.id] : 0;
  }
};

const getNodeExercisesCount = (node: TreeNode, attributes: ExerciseAttribute): number => {
  const hasKeyProperty = Object.prototype.hasOwnProperty.call(attributes, node.key!);
  const hasBothKeyAndIdProperties = hasKeyProperty && Object.prototype.hasOwnProperty.call(attributes[node.key!], node.id!);
  return hasBothKeyAndIdProperties ? attributes[`${node.key}`][node.id] : 0;
};

export const updateLevelFirst = (facets: TreeNode, attributes: ExerciseAttribute) => {
  facets.children?.forEach(node => {
    if (node.lvl === 1) {
      let exercisesCount = 0;
      exercisesCount += getNodeExercisesCount(node, attributes);
      node.children?.forEach(item => {
        exercisesCount += getNodeExercisesCount(item, attributes);
      });
      node.numberOfExercises = exercisesCount;
    } else {
      updateLevelFirst(node, attributes);
    }
  });
};

export const assignNumberOfExerciseToFilters = (facets: TreeNode, level: number, attributes: ExerciseAttribute): void => {
  facets.children?.forEach(child => {
    child.lvl = level;
    updateChildren(attributes, child, level);
    if (child.children) {
      assignNumberOfExerciseToFilters(child, level + 1, attributes);
    }
  });
};
