import { ExerciseState } from './types';

export const state: ExerciseState = {
  cachedExerciseHeight: [],
  cachedExercises: [],
  currentExerciseId: '',
  currentVariantIndex: 0,
  exercises: {
    attribute: {
      skill: {},
      level: {},
      source: {},
      type: {},
      category: {}
    },
    exerciseCount: 0,
    exercises: [],
    earlySchoolEducation: null
  },
  earlySchoolEducation: null,
  exerciseToPreview: {
    exercise: null,
    isOpened: false
  } as any,
  selectedVariants: []
};
