import { Api } from '@/api';
import { SentryEnv } from '@/config';

export interface EnvConfig {
  API_URL: string;
  ENV: SentryEnv;
  SENTRY_DSN: string | null;
  PIWIK_ID: string;
  MNE_URL: string;
  MULTI_API_BASE_URL: string;
}

export const getEnvVariables = async () => {
  let envConfig: EnvConfig = {
    API_URL: '',
    ENV: SentryEnv.DEV,
    SENTRY_DSN: null,
    PIWIK_ID: '',
    MNE_URL: '',
    MULTI_API_BASE_URL: ''
  };
  if (process.env.VUE_APP_ENV_MODE === 'runtime') {
    const api = new Api({});
    try {
      envConfig = (await api.get<EnvConfig>('/environment.json')).data;
    } catch (e) {
      // old app version doesn't use env file
    }
  }
  return envConfig;
};
