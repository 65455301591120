import { SET_HEADER, SET_MENU } from '@/store/list';
import { MutationTree } from 'vuex';
import { LayoutModel, LayoutState, MenuModel } from '@/store/layout-store/types';

export const mutations: MutationTree<LayoutState> = {
  [SET_MENU]: (state, payload: MenuModel) => {
    state.menu = payload;
  },
  [SET_HEADER]: (state, payload: LayoutModel) => {
    state.layout = payload;
  }
};
