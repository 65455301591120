import Vue from 'vue';
import Vuex, { createLogger, Plugin, StoreOptions } from 'vuex';
import { RootState } from '@/store/types';
import { TestStore } from '@/store/test-store/';
import { AppStore } from '@/store/app-store/';
import { ExerciseStore } from '@/store/exercise-store/';
import { LayoutStore } from '@/store/layout-store';
import { AuthStore } from '@/store/auth-store';
import { Api } from '@/api';

const api = new Api({});

Vue.use(Vuex);

const plugins: Plugin<RootState>[] = [];
if (process.env.NODE_ENV === 'development') {
  plugins.push(createLogger());
}

const store: StoreOptions<RootState> = {
  // @ts-ignore
  state: {
    version: '1.0.0',
    $api: api,
    $apiGt: api,
    $apiURL: '',
    $apiMne: api,
    $apiMul: api
  },
  modules: {
    AppStore,
    ExerciseStore,
    TestStore,
    LayoutStore,
    AuthStore
  },
  plugins
};

export default new Vuex.Store<RootState>(store);
