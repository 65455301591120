import Vue from 'vue';
import Router from 'vue-router';
import { basePath } from '@/config';
import appRoutes from './routes/app';
import authRoutes from './routes/auth';
import redirects from './routes/redirects';
import PageNotFound from '@/views/PageNotFound/PageNotFound.vue';
import { RouteName } from '@/router/models';

Vue.use(Router);

const router = new Router({
  mode: 'history',
  base: basePath,
  routes: [
    {
      path: '/zadanie/podglad/:id/:hash',
      name: RouteName.EXERCISE_PREVIEW,
      component: () =>
        import(/* webpackChunkName: "exercise-preview" */ '@/views/ExercisePreview/ExercisePreview.vue')
    },
    {
      path: '/',
      name: 'app-layout',
      component: () => import(/* webpackChunkName: "app-layout" */ '@/layouts/App/App.vue'),
      children: appRoutes
    },
    {
      path: '/',
      name: 'auth-layout',
      component: () => import(/* webpackChunkName: "auth" */ '@/layouts/Auth/Auth.vue'),
      children: authRoutes
    },
    ...redirects,
    { path: '*', component: PageNotFound }
  ]
});

router.beforeEach((to, from, next) => {
  Vue.prototype.$piwik.push({
    event: 'PageView',
    from: from.fullPath,
    to: to.fullPath,
    pageTitle: to.name
  });
  return next();
});

export default router;
