import Vue from 'vue';
import i18n from '@/plugins/i18n';
import '@/plugins/masonry';
import '@/plugins/cookies';
import '@/plugins/ne-vue-components';
import '@/plugins/load-script';
import initApi from '@/plugins/api';
import initSentry from '@/plugins/sentry';
import App from './App.vue';
import router from '@/router';
import store from '@/store';
import { getEnvVariables } from '@/core/env';
import '@/plugins/vue-tour';
import initPiwik from '@/plugins/piwik';

import { applyPolyfills, defineCustomElements } from 'gt-test/dist/loader';

const initializeApp = async () => {
  Vue.config.productionTip = false;

  await applyPolyfills();
  await defineCustomElements(window);
  const {
    API_URL,
    ENV,
    SENTRY_DSN,
    PIWIK_ID,
    MNE_URL,
    MULTI_API_BASE_URL
  } = await getEnvVariables();
  await initApi(API_URL, MNE_URL, MULTI_API_BASE_URL);
  store.state.AppStore.isAuthDevMode = process.env.VUE_APP_AUTH_MODE === 'dev';
  store.state.AppStore.tenantsUrl.mne = MNE_URL;
  initSentry(SENTRY_DSN, ENV);
  initPiwik(PIWIK_ID || '9ab5f194-abff-4a6c-997c-18e3dd22dc52');

  new Vue({
    name: 'GT',
    router,
    store,
    i18n,
    render: (h: any) => h(App)
  }).$mount('#app');

  Vue.config.devtools = true;
};

initializeApp();
