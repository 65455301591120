import { ActionTree } from 'vuex';
import { AppState } from './types';
import { RootState } from '../types';
import { INCREMENT_NOTIFY_COUNTER, REMOVE_NOTIFICATION, RESET_CACHE, SET_DEFAULTS, USER_NOTIFY } from '@/store/list';
import { Notification, NotificationConfig, NotificationType } from '@/models';
import { COPYRIGHT_URI, FACET_URI } from '@/api/generator/endpoints';
import { handleParentNodes } from '@/helpers/facets';

export const actions: ActionTree<AppState, RootState> = {
  async [SET_DEFAULTS] ({ rootState, commit, state }) {
    const _facets = await rootState.$apiGt.get<any>(FACET_URI(state.categoryKey));
    const _copyRights = await rootState.$apiGt.get<any>(COPYRIGHT_URI, {
      params: {
        categoryKey: state.categoryKey
      }
    });
    handleParentNodes(_facets.data);

    const _facetsExercise = _facets.data;
    const _facetsTest = { ..._facets.data };
    _facetsExercise.children = [..._facetsExercise.children, ..._facets.data.exercise];

    const testTop: any[] = [];
    const testEnd: any[] = [];
    _facets.data.test.forEach((obj: any) => {
      handleParentNodes(obj);
      (obj.id === 14) ? testTop.push(obj) : testEnd.push(obj);
    });
    _facetsTest.children = [
      ...testTop,
      ..._facetsTest.children,
      ...testEnd
    ];

    commit(SET_DEFAULTS, {
      facets: _facetsExercise,
      testFacets: _facetsTest,
      copyrights: _copyRights.data.copyright
    });
    commit(`ExerciseStore/${RESET_CACHE}`, null, { root: true });
  },
  async [USER_NOTIFY] ({ commit, state }, data: NotificationConfig): Promise<Notification> {
    let msg: string;
    let type: NotificationType = 'success';
    let permanent: boolean | undefined = false;
    const DEFAULT_TIME_IN_MS = 2000;
    let timeInMs;
    if (typeof data === 'string') {
      msg = data;
      timeInMs = DEFAULT_TIME_IN_MS;
    } else {
      msg = data.msg;
      type = data.type;
      permanent = data.permanent;
      timeInMs = data.timeInMs ?? DEFAULT_TIME_IN_MS;
    }

    commit(INCREMENT_NOTIFY_COUNTER);
    const notify: Notification = {
      id: state.notifyCounter,
      message: msg,
      type,
      timeInMs
    };
    commit(USER_NOTIFY, notify);
    if (!permanent) {
      setTimeout(() => {
        commit(REMOVE_NOTIFICATION, notify);
      }, timeInMs);
    }
    return notify;
  }
};
