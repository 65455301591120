import { GetterTree } from 'vuex';
import { TestState } from './types';
import { RootState } from '../types';
import {
  GET_EXERCISE_COUNTER,
  GET_PAGE_COUNTER,
  GET_SCORE_COUNTER,
  GET_TEST_TITLE,
  GET_TEST_STATE,
  GET_TEST_HEADER,
  GET_ATTACHMENT_COUNTER,
  GET_TEST_TIME,
  GET_ANSWER_CARD_NOT_DOWNLOADABLE
} from '@/store/list';

export const getters: GetterTree<TestState, RootState> = {
  [GET_TEST_TITLE]: state => state.currentTest.title || '',
  [GET_TEST_STATE]: state => state.currentTest.published,
  [GET_TEST_TIME]: state => state.testTime,
  [GET_TEST_HEADER]: state => state.currentTest.header,
  [GET_PAGE_COUNTER]: state => state.currentTest.variants[0].pages.length ?? 0,
  [GET_EXERCISE_COUNTER]: state => {
    if (state.currentTest?.variants?.length) {
      return state.currentTest.variants[0].pages.reduce((acc: any, val: any) => acc.concat(val), []).length ?? 0;
    } return [];
  },
  [GET_SCORE_COUNTER]: state => {
    const score = state.totalScore || 0;
    return score > 0 ? score : 0;
  },
  [GET_ATTACHMENT_COUNTER]: state => {
    const pages = state.currentTest.variants[0].pages;
    return pages
      .reduce((acc: any, val: any) => acc.concat(val), [])
      .filter((exercise: any) => exercise.attachments === true || exercise.attachments.length > 0).length;
  },
  [GET_ANSWER_CARD_NOT_DOWNLOADABLE]: state => {
    return state.isAnswerCardNotDownloadable;
  }
};
