import { MutationTree } from 'vuex';
import { AppState } from './types';
import {
  DISPLAY_RECREATED,
  INCREMENT_NOTIFY_COUNTER,
  REMOVE_NOTIFICATION,
  SET_CATEGORY_KEY,
  SET_CURRENT_STEP,
  SET_DEFAULTS,
  SET_PAGE_ID,
  SET_LOGIN_STATUS,
  SET_ROLE,
  SET_FILTERS_ACTIVE,
  SET_STRUCTURE_STATUS,
  TOGGLE_FACETS,
  USER_NOTIFY
} from '@/store/list';
import { LoginStatus, Notification, StructureStatusName } from '@/models';
import { GtPageTypes, ROLES } from '@/config';

export const mutations: MutationTree<AppState> = {
  [SET_DEFAULTS]: async (state: AppState, data) => {
    state.facets = data.facets;
    state.testFacets = data.testFacets;
    state.copyrights = data.copyrights;
  },
  [SET_PAGE_ID]: (state, pageId: GtPageTypes) => {
    state.lastPageId = state.pageId;
    state.pageId = pageId;
  },
  [SET_CATEGORY_KEY]: (state, categoryKey: string) => {
    state.categoryKey = categoryKey.replace(/,/g, '/');
  },
  [SET_ROLE]: (state, data: ROLES[]) => {
    state.userRole = data;
  },
  [TOGGLE_FACETS]: (state, isShown: boolean) => {
    state.facetsToggled = isShown;
  },
  [SET_CURRENT_STEP]: (state, step) => {
    state.lastStep = JSON.parse(JSON.stringify(state.step));
    state.step = step;
  },
  [USER_NOTIFY]: (state, notify: Notification) => {
    state.notifications.push(notify);
  },
  [REMOVE_NOTIFICATION]: (state, notify: Notification) => {
    if (notify) state.notifications = state.notifications.filter(item => item.id !== notify.id);
  },
  [DISPLAY_RECREATED]: state => {
    state.displayGroupsRecreated = true;
  },
  [SET_STRUCTURE_STATUS]: (state, structureStatus: StructureStatusName): void => {
    state.structureStatus = structureStatus;
  },
  [INCREMENT_NOTIFY_COUNTER]: state => {
    state.notifyCounter++;
  },
  [SET_FILTERS_ACTIVE]: (state: AppState, isActive: boolean) :void => {
    state.isFiltersActive = isActive;
  },
  [SET_LOGIN_STATUS]: (state: AppState, loginsStatus: LoginStatus): void => {
    state.loginStatus = loginsStatus;
  }
};
