import { MutationTree } from 'vuex';
import { ExerciseState } from './types';
import {
  CLOSE_PREVIEW_MODAL,
  FILTER_EXERCISES,
  RESET_CACHE,
  RESET_SELECTED_VARIANTS,
  SET_EXERCISES_DETAILS,
  SET_EXERCISE_TO_PREVIEW,
  SET_SELECTED_VARIANTS
} from '@/store/list';
import { Exercise, ExercisePreViewConfig, ExerciseStoreResponse } from '@/models';
import { removeDuplicatedObjArr } from '@/helpers/array-manipulation';
import { SelectedVariant } from '@/models/exercises';

export const mutations: MutationTree<ExerciseState> = {
  [CLOSE_PREVIEW_MODAL] (state) {
    state.exerciseToPreview = {
      exercise: null,
      isOpened: false
    } as any;
  },
  [FILTER_EXERCISES] (state, exercises: ExerciseStoreResponse) {
    state.exercises = exercises;
    state.earlySchoolEducation = exercises.earlySchoolEducation;
  },
  [RESET_CACHE]: state => {
    state.cachedExercises = [];
  },
  [RESET_SELECTED_VARIANTS]: state => {
    state.selectedVariants = [];
  },
  async [SET_EXERCISES_DETAILS] (state, exercises: Exercise[]): Promise<void> {
    state.cachedExercises = removeDuplicatedObjArr(state.cachedExercises.concat(exercises), ['id']);
  },
  [SET_EXERCISE_TO_PREVIEW] (state, payload: ExercisePreViewConfig) {
    state.exerciseToPreview = payload;
  },
  [SET_SELECTED_VARIANTS] (state: ExerciseState, payload: SelectedVariant) {
    state.selectedVariants = state.selectedVariants.filter(exercise => exercise.exerciseId !== payload.exerciseId);
    state.selectedVariants.push(payload);
  }
};
