import { MutationTree } from 'vuex';
import { TestState } from './types';
import {
  CREATE_NEW_TEST,
  SET_ANSWER_CARD_NOT_DOWNLOADABLE,
  SET_TEST_DATABASE,
  SET_TEST_PREVIEW,
  SET_TEST_VALIDATION_ACTIVE,
  UPDATE_ALL_TEST_VARIANTS,
  UPDATE_EXERCISE_VARIANT,
  UPDATE_TEST,
  UPDATE_TEST_AFTER_ANSWERS_MODIFICATION,
  UPDATE_TEST_AFTER_EXERCISE_ADD,
  UPDATE_TEST_AFTER_EXERCISE_REMOVE,
  UPDATE_TEST_AFTER_EXERCISE_REORDER,
  UPDATE_TEST_AFTER_EXERCISE_SAVE,
  UPDATE_TEST_TIME,
  UPDATE_TEST_TITLE,
  UPDATE_TOTAL_SCORE,
  UPDATE_EXERCISE_PRINT_HEIGHT
} from '@/store/list';
import { ExerciseDetails, Page, TestModel, TestsResponseModel, TestVariant } from '@/models';
import {
  exercisesInTable, isSecondStudentSection,
  numberOfCells,
  teacherSectionExercises
} from '@/helpers/answer-card.helper';
import { AnswerCardLimits } from '@/config';
import { ExerciseAnswerField, ExerciseVariant } from '@/models/exercises';

export const mutations: MutationTree<TestState> = {
  [CREATE_NEW_TEST]: (state, test) => {
    state.currentTest = test;
  },
  [SET_TEST_DATABASE]: (state, testResponse: TestsResponseModel) => {
    state.tests = testResponse;
  },
  [SET_TEST_PREVIEW]: (state, test) => {
    state.testPreView = test;
  },
  [UPDATE_TEST]: (state, test: TestModel) => {
    state.currentTest = test;
  },
  [UPDATE_TEST_TITLE]: (state, title) => {
    state.currentTest.title = title;
  },
  [UPDATE_TOTAL_SCORE]: (state, totalScore: number) => {
    const _totalScore = totalScore > 0 ? totalScore : 0;
    state.totalScore = _totalScore;
  },
  [UPDATE_TEST_TIME]: (state, time) => {
    state.testTime = time;
  },
  [UPDATE_TEST_AFTER_EXERCISE_ADD]: (state, data) => {
    state.currentTest.variants[0].pages = data;
  },
  [UPDATE_TEST_AFTER_EXERCISE_REMOVE]: (state, data) => {
    state.currentTest.variants = data;
  },
  [UPDATE_TEST_AFTER_EXERCISE_REORDER]: (state, data) => {
    const _test = state.currentTest;
    _test.variants[data.variantIndex].pages = data.pages;
    state.currentTest = _test;
  },
  [UPDATE_ALL_TEST_VARIANTS]: (state, variants) => {
    state.currentTest.variants = variants;
  },
  [UPDATE_TEST_AFTER_ANSWERS_MODIFICATION]: async (state, payload: ExerciseAnswerField) => {
    const tempTest = state.currentTest;
    tempTest.variants.forEach((variant: any) => {
      variant.pages.forEach((page: any) => {
        page.forEach((pExercise: any, index: number) => {
          if (pExercise.id === payload.id) {
            pExercise.answerFieldType = payload.answerFieldType;
            pExercise.answerKey = payload.answerKey;
            pExercise.answerFieldCount = payload.answerFieldCount;
            page[index] = pExercise;
          }
        });
      });
    });
    state.currentTest = tempTest;
  },
  [UPDATE_TEST_AFTER_EXERCISE_SAVE]: async (state, payload: ExerciseDetails) => {
    const tempTest = state.currentTest;
    tempTest.variants.forEach((variant: TestVariant) => {
      variant.pages.forEach((page: Page[]) => {
        page.forEach((exercise: Page) => {
          if (exercise.id === payload.id) {
            payload.variants.forEach((variant: ExerciseVariant) => {
              if (variant.id === exercise.variantId) {
                exercise.time = payload.time;
                exercise.score = payload.score;
              }
            });
          }
        });
      });
    });
    state.currentTest = tempTest;
  },
  [UPDATE_EXERCISE_VARIANT] (state, { groupIndex, orderPageIndex, orderExerciseIndex, selectedVariant }) {
    state.currentTest.variants[groupIndex].pages[orderPageIndex][orderExerciseIndex].variantId = selectedVariant;
  },
  [UPDATE_EXERCISE_PRINT_HEIGHT] (state, { groupIndex, orderPageIndex, orderExerciseIndex, printHeight, isRedactorExam }) {
    if (isRedactorExam) {
      const flatTestPages = state.currentTest.variants[groupIndex].pages.flat();
      flatTestPages[orderExerciseIndex].printHeight = printHeight;
    } else {
      state.currentTest.variants[groupIndex].pages[orderPageIndex][orderExerciseIndex].printHeight = printHeight;
    }
  },
  [SET_TEST_VALIDATION_ACTIVE] (state, payload: boolean) {
    state.testValidationActive = payload;
  },
  [SET_ANSWER_CARD_NOT_DOWNLOADABLE] (state) {
    const exercises = state.currentTest.variants[0].pages.flat();
    const teacherExercises = teacherSectionExercises(exercises);
    if (isSecondStudentSection(exercises, AnswerCardLimits.cellsOnLeftSide)) {
      const exercisesInSecondTable = exercisesInTable(1, exercises, AnswerCardLimits.cellsOnLeftSide);
      const cells = numberOfCells(exercisesInSecondTable);
      state.isAnswerCardNotDownloadable = teacherExercises.length
        ? teacherExercises.length >
        AnswerCardLimits.cellsOnLeftSide - (cells + 4)
        : cells > AnswerCardLimits.cellsOnLeftSide;
    } else {
      state.isAnswerCardNotDownloadable = teacherExercises.length > AnswerCardLimits.cellsOfTeacherSection;
    }
  }
};
