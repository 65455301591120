import { GetterTree } from 'vuex';
import { LayoutState } from './types';
import { RootState } from '../types';
import { FOOTER, HEADER, USER_DATA } from '@/store/list';

export const getters: GetterTree<LayoutState, RootState> = {
  [FOOTER]: state => state.layout.footer,
  [HEADER]: state => state.layout.header,
  [USER_DATA]: state => state.layout.user
};
