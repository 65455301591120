import { GetterTree } from 'vuex';
import { ExerciseState } from './types';
import { RootState } from '../types';
import {
  EXERCISES_LIST,
  GET_EXERCISE_MAX_HEIGHT,
  GET_SELECTED_VARIANT,
  TOTAL_EXERCISES_FOUND
} from '@/store/list';
import { CachedPrintHeight } from '@/models';
import { SelectedVariant } from '@/models/exercises';

export const getters: GetterTree<ExerciseState, RootState> = {
  [TOTAL_EXERCISES_FOUND]: state => state.exercises.exerciseCount,
  [EXERCISES_LIST]: state => state.exercises.exercises,
  [GET_EXERCISE_MAX_HEIGHT]: state => (exerciseId: number) => {
    const _tmpExercise = state.cachedExerciseHeight.filter((ce: CachedPrintHeight) => ce.id === exerciseId);
    return _tmpExercise.length > 0 ? _tmpExercise[0].maxHeight : 0;
  },
  [GET_SELECTED_VARIANT]: state => (exerciseId: number): number => {
    const selectedVariant = state.selectedVariants.find((e: SelectedVariant) => e.exerciseId === exerciseId);
    return selectedVariant ? selectedVariant.variantIndex : 0;
  }
};
