import { ActionTree } from 'vuex';
import { TestState } from './types';
import { RootState } from '../types';
import { TestModel } from '@/models';
import {
  ADD_TEST_GROUP,
  DELETE_TEST_ID,
  EXERCISES_ADD,
  EXERCISES_ORDER,
  EXERCISES_REMOVE,
  FETCH_TEST,
  FETCH_TEST_DATABASE,
  FETCH_TEST_PREVIEW,
  GET_TEST,
  RECALCULATE_TEST_VARIANTS,
  SAVE_CHANGES_IN_TEST,
  SAVE_NEW_TEST,
  SET_ANSWER_CARD_NOT_DOWNLOADABLE,
  SET_TEST_DATABASE,
  SET_TEST_PREVIEW,
  UPDATE_ALL_TEST_VARIANTS,
  UPDATE_TEST,
  UPDATE_TEST_AFTER_EXERCISE_ADD,
  UPDATE_TEST_AFTER_EXERCISE_REMOVE,
  UPDATE_TEST_AFTER_EXERCISE_REORDER,
  UPDATE_TEST_BEFORE_PDF,
  UPDATE_TEST_TIME,
  UPDATE_TOTAL_SCORE,
  VARIANTS_ORDER
} from '@/store/list';
import {
  TEST_LIST_URI,
  TEST_URI,
  TEST_URI_ADD_GROUP,
  TEST_URI_DELETE,
  TEST_URI_PREVIEW,
  TEST_URI_RECALCULATE,
  TEST_URI_SAVE,
  TEST_URI_UPDATE
} from '@/api/generator/endpoints';
import { facetsToUri, prepareQueryParameters, QueryParameter } from '@/api/generator/helpers';
import { TestsFilters } from '@/models/tests';
import { DEFAULT_TEST_SHOW_LIMIT, DIRECTIONS } from '@/config';

import { cloneNoRef } from '@/helpers';
import { addExercise, order, removeExercise, reorderTestExercises } from '@/helpers/order';
import { ExerciseToAdd } from '@/models/exercises';

export const actions: ActionTree<TestState, RootState> = {
  async [FETCH_TEST_DATABASE] ({ rootState, commit }, data: TestsFilters) {
    const order = `${data.sortField}${data.sortOrder.charAt(0).toUpperCase() + data.sortOrder.slice(1)}`;
    const params: QueryParameter[] = [
      { key: 'categoryKey', value: data.categoryKey },
      { key: 'page', value: data.page },
      { key: 'limit', value: DEFAULT_TEST_SHOW_LIMIT },
      { key: 'order', value: order }
    ];
    if (data.searchText && data.searchText.length > 0) {
      params.push({ key: 'text', value: data.searchText });
    }
    const query = `${TEST_LIST_URI}${prepareQueryParameters(params)}${facetsToUri(data.activeFacets)}`;
    const tests = await rootState.$apiGt.get(query);
    commit(SET_TEST_DATABASE, tests.data);
  },
  async [FETCH_TEST_PREVIEW] ({ rootState, commit, dispatch }, testId) {
    const response = await rootState.$apiGt.get(TEST_URI_PREVIEW(testId));
    if (response.status === 200) {
      commit(SET_TEST_PREVIEW, response.data);
      commit(UPDATE_TEST, response.data);
      dispatch(UPDATE_TEST_TIME);
    }
  },
  async [FETCH_TEST] ({ rootState, commit, dispatch }, testId) {
    const test: TestModel = (await rootState.$apiGt.get<TestModel>(TEST_URI_PREVIEW(testId))).data;
    commit(UPDATE_TEST, test);
    dispatch(UPDATE_TEST_TIME);
    return test;
  },
  async [GET_TEST] ({ rootState, commit }, testId) {
    const response = await rootState.$apiGt.get(`${TEST_URI}/${testId}`);
    if (response.status === 200) {
      commit(UPDATE_TEST, response.data);
    }
  },
  async [RECALCULATE_TEST_VARIANTS] ({ rootState, state, commit }, shouldLeaveOneGroupOnly: boolean) {
    const test: TestModel = (await rootState.$apiGt.put<TestModel>(TEST_URI_RECALCULATE, { ...state.currentTest, leaveOneGroup: shouldLeaveOneGroupOnly })).data;
    commit(UPDATE_TEST, test);
  },
  async [ADD_TEST_GROUP] ({ rootState, commit, state }) {
    if (state.currentTest.variants.length) {
      const test: TestModel = (await rootState.$apiGt.put<TestModel>(TEST_URI_ADD_GROUP, state.currentTest)).data;
      commit(UPDATE_TEST, test);
    }
  },
  async [SAVE_NEW_TEST] ({ rootState, state }) {
    await rootState.$apiGt.post<TestModel>(TEST_URI_SAVE, state.currentTest);
  },
  async [SAVE_CHANGES_IN_TEST] ({ rootState, state }) {
    await rootState.$apiGt.put<TestModel>(TEST_URI_UPDATE(state.currentTest.id), state.currentTest);
  },
  async [UPDATE_TEST_BEFORE_PDF] ({ rootState, state }) {
    const obj = Object.assign({ status: false }, state.currentTest);
    await rootState.$apiGt.put(`${TEST_URI}/${state.currentTest.id}`, obj, { headers: { loading: 'skip' } });
  },
  async [DELETE_TEST_ID] ({ rootState }, testId: number) {
    await rootState.$apiGt.delete(TEST_URI_DELETE(testId));
  },
  [EXERCISES_ADD] ({ commit, state, dispatch }, data: ExerciseToAdd) {
    const variantsPages = state.currentTest.variants?.length ? state.currentTest.variants[0].pages : [];
    const _tmpPages: any[] = addExercise(cloneNoRef(variantsPages), data.exercise, state.currentTest.earlySchoolEducation);
    const counter = state.totalScore + data.exercise.score;
    commit(UPDATE_TOTAL_SCORE, counter);
    commit(UPDATE_TEST_AFTER_EXERCISE_ADD, _tmpPages);
    commit(SET_ANSWER_CARD_NOT_DOWNLOADABLE);
    dispatch(UPDATE_TEST_TIME);
  },
  [EXERCISES_REMOVE] ({ commit, state, dispatch }, data) {
    for (const variant of state.currentTest.variants) {
      variant.pages = removeExercise(variant.pages, data.id, state.currentTest.earlySchoolEducation);
    }
    const counter = state.totalScore - parseInt(data.score || data.scoring);
    commit(UPDATE_TOTAL_SCORE, counter);
    commit(UPDATE_TEST_AFTER_EXERCISE_REMOVE, state.currentTest.variants);
    commit(SET_ANSWER_CARD_NOT_DOWNLOADABLE);
    dispatch(UPDATE_TEST_TIME);
  },
  [VARIANTS_ORDER] ({ commit, state }, payload) {
    const variants = cloneNoRef(state.currentTest.variants);
    reorderTestExercises(variants, payload, state.currentTest.earlySchoolEducation, payload.isRedactorExam);
    commit(UPDATE_ALL_TEST_VARIANTS, variants);
  },
  [EXERCISES_ORDER] ({ dispatch, commit, state, rootState }, payload) {
    if (payload.direction === DIRECTIONS.NONE) {
      if (rootState.AppStore.step === 2) {
        dispatch(VARIANTS_ORDER, payload);
      } else {
        const pages = order(
          cloneNoRef(state.currentTest.variants[0].pages),
          payload.exerciseIndex,
          payload.pageIndex,
          payload.direction,
          state.currentTest.earlySchoolEducation,
          payload.doSwap,
          payload.isRedactorExam
        );
        commit(UPDATE_TEST_AFTER_EXERCISE_REORDER, {
          pages,
          variantIndex: payload.variantIndex
        });
      }
    } else {
      const pages = order(
        cloneNoRef(state.currentTest.variants[payload.variantIndex].pages),
        payload.exerciseIndex,
        payload.pageIndex,
        payload.direction,
        state.currentTest.earlySchoolEducation,
        payload.doSwap,
        payload.isRedactorExam
      );
      commit(UPDATE_TEST_AFTER_EXERCISE_REORDER, {
        pages,
        variantIndex: payload.variantIndex
      });
    }
  },
  [UPDATE_TEST_TIME] ({ state, commit }) {
    let mappedTimes: any[] = [];
    const variantsPages = state.currentTest.variants?.length ? state.currentTest.variants[0].pages : [];
    for (const pages of variantsPages) {
      mappedTimes = [...mappedTimes, ...pages.map((page: any) => page.time)];
    }
    const time = mappedTimes?.includes(null) ? null : mappedTimes?.reduce((prev: number, curr: number) => prev + curr, 0);
    commit(UPDATE_TEST_TIME, time);
  }
};
