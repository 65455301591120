// endpoints
export const MENU_LAYOUT = (categoryKey: string) => `menu/${categoryKey}`;
export const HEADER_LAYOUT = 'headerFooter';
export const FACETS = (categoryKey: string) => `facet/${categoryKey}`;
export const EXERCISE_EDIT = (exerciseId: string) => `exercises/${exerciseId}`;
export const EXERCISE_CREATE = (categoryKey: string) => `exercises/create/${categoryKey}`;
export const EXERCISE_SAVE = 'exercises/create';
export const EXERCISE_UPDATE = (id: number|string) => `exercises/${id}`;
export const EXERCISE_COMMENTS = 'exercises/comments';
export const EXERCISE_COPY = (exerciseId: number, categoryId: number) => `exercises/${exerciseId}/copy/${categoryId}`;
export const IMAGES_CATEGORY = (id: number) => `images/category/${id}`;
export const IMAGES_LISTS = (id: number|null, sort: string) => `images/lists/${id}?sort=${sort}`;
export const IMAGES_REMOVE = (id: number) => `images/${id}`;
export const IMAGE_SAVE = 'images';

// user
export const URI_AUTHORIZE_URL = 'user/authorize/url';
export const URI_REGISTER_URL = 'user/authorize/url/base';
export const URI_AUTHORIZE = 'user/authorize';
export const URI_AUTHORIZE_HASH = 'user/authorize/hash';
export const URI_LOGOUT = 'user/logout';
