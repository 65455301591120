
import { RouteName } from '../models';

export default [
  {
    path: '/logowanie',
    name: RouteName.LOGIN,
    component: () => import(/* webpackChunkName: "login" */ '@/views/Login/Login.vue')
  },
  {
    path: '/rejestracja',
    name: RouteName.REGISTER,
    component: () => import(/* webpackChunkName: "register" */ '@/views/Register/Register.vue')
  },
  {
    path: '/autoryzacja',
    name: RouteName.AUTHORIZATION,
    component: () => import(/* webpackChunkName: "authorization" */ '@/views/Authorization/Authorization.vue')
  },
  {
    path: '/autoryzacja/hash/:hash',
    name: RouteName.AUTHORIZATION_HASH,
    component: () => import(/* webpackChunkName: "authorization-hash" */ '@/views/AuthorizationHash/AuthorizationHash.vue')
  },
  {
    path: '/wylogowanie/:error?',
    name: RouteName.LOGOUT,
    component: () => import(/* webpackChunkName: "logout" */ '@/views/Logout/Logout.vue')
  }
];
