import { SelectedFacet } from './facets';
import { Active, ActivityStatus, AnswerFieldType } from '@/config';
import { ExercisePreviewTemplateEnum } from '@/views/Generator/ExerciseComposer/components/ExerciseContent/ExerciseTemplatePreviewModal/exercise-preview-template.enum';
import { EditorModeName } from '@/views/Generator/ExerciseComposer/components/ExerciseContent/ExerciseContentEditor/models';
import { ExerciseInTest } from '@/models/index';

export enum TeacherCommentAnswerEnum {
  NO,
  YES
}

export interface ExerciseTeacherComment {
  teacherAnswer: TeacherCommentAnswerEnum | null;
  comment: string | null;
  exerciseId: number;
  variantId: number;
  isSent: boolean;
}

export interface ExercisesFilters {
  activeFacets?: SelectedFacet[];
  categoryKey: string;
  activity?: ActivityStatus;
  limit: number;
  p: number;
  searchQuery: string;
  sort: string;
}

export interface ExerciseVariant {
  additionalAnswers: ExerciseAnswerAdditional[] | null;
  answerKey: boolean;
  answers: ExerciseAnswer[] | null;
  author: SourceName;
  bundles: Bundle[] | null;
  content: string;
  comment?: ExerciseTeacherComment;
  id: number;
  justificationWord: string | null;
  printHeight: number;
  printHeightSolution: number;
  solution?: string | null;
}

export interface ExerciseAttachment {
  filename: string;
  id?: number;
  urlDownload: string;
}

export interface ExerciseAnswer {
  booleanAnswer: BooleanAnswerType | null;
  content: string;
  height: number;
  id?: number | null;
  isCorrect: boolean;
}
export type SortOrder = 'desc' | 'asc';

export interface SortData {
  key: string;
  sortField: string;
  sortOrder: SortOrder;
}

export interface ExerciseHint {
  id?: number | null;
  content: string;
}

export interface ExerciseAnswerAdditional extends ExerciseAnswer {
  correctAnswers: number[];
}

export interface ExerciseFieldOption {
  children?: ExerciseFieldOption[];
  id: number;
  label?: string;
  name?: string;
}

export interface ExerciseSourceOption {
  id: number;
  label: SourceName;
}

export interface ExerciseStringField {
  description: string | null;
  label: string | null;
  options?: ExerciseFieldOption[];
  required: boolean;
  type: string;
  value: string | null;
}

export interface ExerciseField {
  description: string | null;
  label: string | null;
  options?: ExerciseFieldOption[] | null;
  required: boolean;
  type: string;
  value: number | null;
}

export interface ExerciseSourceField {
  description: string | null;
  label: string | null;
  options?: ExerciseSourceOption[];
  required: boolean;
  type: string;
  value: number | null;
}

export interface ExerciseMultipleField {
  description: string | null;
  label: string | null;
  options?: ExerciseFieldOption[];
  required: boolean;
  type: string;
  value: number[] | null;
}

export interface AnswerType {
  id: AnswersType;
  text: string;
  options?: {
    english: BooleanAnswerOption[],
    german: BooleanAnswer[],
    default: BooleanAnswer[]
  }
}

export interface BooleanAnswerOption {
  id?: number;
  title: string;
  templates: BooleanAnswer[];
}

export interface BooleanAnswer {
  label: string;
  value: number
}

export enum AnswersType {
  CLOSED = 1,
  OPEN,
  OPEN_CLOSED,
  SINGLE_CHOICE,
  MULTIPLE_CHOICE,
  BOOLEAN,
  BUNDLES,
  JUSTIFICATION,
  ASSIGNMENT,
  FLASHCARD,
  BUNDLES_EXAM,
  NUMERIC,
  LIST,
  CUSTOM_LIST,
  TEXT,
  ORDER_SETTING,
}

export enum BooleanAnswerType {
  FALSE,
  TRUE,
  DS
}

export type ExerciseType = {
  [key in AnswersType]?: string;
}

export interface ExerciseCreateData {
  active?: Active;
  additionalContent?: ExerciseAdditionalContent;
  answersTemplate: ExercisePreviewTemplateEnum | null;
  answersType: AnswersType | null;
  attachments?: ExerciseAttachment[];
  author?: SourceName;
  bundles: null | Bundle[];
  categoryId: number | null;
  categoryImageId: number | null;
  chapter?: string | null
  comment: ExerciseStringField;
  copyright: string | null;
  earlySchoolEducation: boolean | null;
  editor: EditorModeName | null;
  englishTemplate: number | null;
  exerciseInTests: ExerciseInTest[];
  exerciseType: ExerciseType;
  hash?: string;
  id?: number;
  isOwner?: boolean | null;
  isAnswerSpace: boolean | null;
  level?: ExerciseField | null;
  newAttachment?: File;
  newFlagDays: ExerciseField | null;
  object?: string;
  objectId?: number;
  objectMedia?: string;
  range?: ExerciseField | null;
  requirements: ExerciseStringField;
  score: ExerciseField;
  section: ExerciseField;
  skills?: ExerciseMultipleField | null;
  source: ExerciseSourceField;
  t?: number;
  time: ExerciseField;
  title: ExerciseStringField | null;
  variants: Variant[];
}

export interface ExerciseAdditionalContent {
  content: string;
  source: string;
}

export interface ExerciseTemplate {
  image: string;
  template: string;
  title: string;
  type?: ExercisePreviewTemplateEnum | null;
}

export interface ExerciseToSave {
  additionalContent?: ExerciseAdditionalContent;
  answersTemplate: ExercisePreviewTemplateEnum | null;
  answersType?: AnswersType | null;
  englishTemplate?: number | null;
  active?: Active;
  categoryId: number | null;
  comment: string | null;
  copyright: string | null;
  deleteFile?: boolean;
  id?: number;
  isAnswerSpace: boolean | null;
  level: number | null;
  newFlagDays: number | null;
  range: number | null;
  requirements: string | null;
  score: number | null;
  section: number | null;
  skills: number[] | null;
  source: number | null;
  time: number | null;
  title: string | null;
  variants: Variant[];
}

export interface ExerciseToAdd {
  exercise: ExerciseInCurrentTest;
}

export interface ExerciseInCurrentTest {
  active: Active;
  attachments?: any[] | boolean;
  chapter: string;
  id: number;
  level: string | null;
  printHeight: number;
  score: number;
  section: string;
  task: string;
  time?: number;
  title: string | null;
  variantId: number;
  variantNumber: number;
  variants?: ExerciseVariant[];
}

export type PageModeName = 'editor' | 'composer';

export enum PageMode {
  COMPOSER = 'composer',
  EDITOR = 'editor'
}

export interface SaveError {
  error: { [key: string]: string };
  save: boolean;
  valid: boolean;
}

export interface SelectedVariant {
  exerciseId: number;
  variantIndex: number;
}

export interface SelectedOption {
  id: number | null;
  label: string;
}

export type SourceName = 'CKE' | 'Nowa Era' | 'Twoje';

export enum Source {
  CKE = 'CKE',
  MY = 'Twoje',
  NOWA_ERA = 'Nowa Era'
}

export interface Variant {
  additionalAnswers: ExerciseAnswerAdditional[] | null;
  answerKey: boolean;
  answers: ExerciseAnswer[] | null;
  author?: SourceName | '';
  bundles: Bundle[] | null;
  content: string;
  explanation?: string;
  hints: ExerciseHint[] | null;
  id?: number;
  isContentInvalid?: boolean;
  isSolutionInvalid?: boolean;
  justificationWord: string | null;
  options?: string[];
  printHeight: number;
  printHeightSolution?: number;
  solution?: string;
  templateContent?: string;
}

export interface Bundle {
  answers: ExerciseAnswer[] | null;
  answersType: AnswersType;
  hints?: ExerciseHint[];
  id?: number;
  isContentInvalid?: boolean;
  printHeight: number;
  printHeightSolution?: number;
  score: number;
  subContent: string;
  template: ExercisePreviewTemplateEnum,
}

export interface MultiSelectItem {
  id: number;
  isSelected: boolean;
  text: string;
  metadata?: any;
}

export interface ExerciseAnswerField {
  id: number,
  answerKey: boolean,
  answerFieldType: AnswerFieldType | null,
  answerFieldCount: number
}
