const removeItemFromArray = (item: any, array: any[]): any[] => {
  return array.filter(el => el !== item);
};

const removeItemsFromArray = (items: any[], array: any[]): any[] => {
  return array.filter(el => !items.includes(el));
};

// Creates an array with all falsy values removed.
const compact = (arr: Array<any>): Array<any> => {
  return arr.filter(Boolean);
};

const find = (arr: any[], id: string | number) => {
  return arr.find(elem => {
    return elem.id === id;
  });
};

const removeDuplicatedObjArr = (arr: Array<any>, keys: string[]) => {
  return arr.filter((s => (o: any) => (k => !s.has(k) && s.add(k))(keys.map(k => o[k]).join('|')))(new Set()));
};

const groupBy = (xs: any, key: string) => {
  return xs.reduce((rv: any, x: any) => {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};

export {
  removeItemFromArray,
  removeItemsFromArray,
  compact,
  find,
  removeDuplicatedObjArr,
  groupBy
};
