import { LayoutState } from './types';

export const state: LayoutState = {
  layout: {
    messenger: [],
    footer: null,
    header: null,
    user: null
  },
  menu: null
};
