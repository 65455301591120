import { TestState } from './types';
import { DEFAULT_TEST_SHOW_LIMIT, EMPTY_TEST } from '@/config';
import { cloneNoRef } from '@/helpers';

export const state: TestState = {
  cachedPrintHeights: [],
  currentTest: cloneNoRef(EMPTY_TEST),
  showOndorio: false,
  isAnswerCardNotDownloadable: true,
  testPreView: null,
  testFiles: {
    pdf: {},
    attachments: []
  },
  testId: 0,
  testEditId: 0,
  tests: {
    numberOfTests: 0,
    numberOfPages: 0,
    activePage: 0,
    tests: []
  },
  totalScore: 0,
  maxTestPages: 0,
  pageTestLimit: DEFAULT_TEST_SHOW_LIMIT,
  testHash: '',
  testValidationActive: false,
  testTime: 0
};
