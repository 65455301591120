export enum CookieName {
  DISABLE_STEP_TWO_MODAL = 'gt-disable-step-two-modal',
  DISABLE_NEW_STRUCTURE_MODAL = 'gt_disable_new_structure_modal',
  DISABLE_OLD_STRUCTURE_MODAL = 'gt_disable_old_structure_modal',
  LOGIN_STATUS = 'gt-login-status',
  LOGOUT_STATUS = 'gt-logout-status',
  USER_TOKEN = 'gt-user-token',
  USER_HASH = 'gt-user-hash',
  LOGIN_RETURN_PATH = 'gt-login-return-path'
}
