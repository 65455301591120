import { Module } from 'vuex';
import { state } from './state';
import { mutations } from './mutations';
import { AuthState } from './types';
import { RootState } from '../types';

const namespaced = true;

export const AuthStore: Module<AuthState, RootState> = {
  namespaced,
  state,
  mutations
};
