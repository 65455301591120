import { AppState } from './types';
import { ROLES } from '@/config';

export const state: AppState = {
  loginStatus: null,
  token: '',
  categoryKey: '',
  pageId: 0,
  lastPageId: 0,
  userRole: [ROLES.TEACHER],
  step: 1,
  lastStep: 1,
  tenantsUrl: {
    mne: ''
  },
  facets: {
    id: 1,
    name: 'root',
    children: []
  },
  testFacets: {
    id: 1,
    name: 'root',
    children: []
  },
  copyrights: null,
  facetsToggled: false,
  notifications: [],
  structureStatus: null,
  notifyCounter: 0,
  isAuthDevMode: null,
  isFiltersActive: true
};
