import { TestModel, TestTypeIdEnum } from '@/models';
import i18n from '@/plugins/i18n';

export const basePath: string = process.env.VUE_APP_BASE_PATH || '';
export const resourcePath = process.env.VUE_APP_PUBLIC_PATH || '';

export const EMPTY_TEST: TestModel = {
  title: '',
  header: {
    date: false,
    grade: false,
    number: false,
    score: true,
    time: false,
    title: false
  },
  published: false,
  variants: [
    {
      pages: [[]]
    }
  ],
  attachments: [],
  files: [],
  categoryKey: '',
  earlySchoolEducation: false,
  generatorTestTypeId: TestTypeIdEnum.TEST
};

export enum ROLES {
  ADMIN = 'admin',
  GUEST = 'guest',
  ONDORIO = 'ondorio',
  PUBLISHER = 'publisher',
  REDACTOR_DLU = 'redactor-dlu',
  REDACTOR_EXAM = 'redactor-exam',
  REDACTOR_GT = 'redactor-gt',
  TEACHER = 'teacher',
  REDACTOR_NSML = 'redactor-nsml'
}

export const DEFAULT_EXERCISE_LIMIT = 10;
export const DEFAULT_TEST_SHOW_LIMIT = 8;
export enum SEARCH_MODES {
  EXERCISE,
  TEST_DATABASE
}

export const pageDimensions = {
  a4PageHeight: 1122,
  pageContentHeight: 1045,
  pageHeaderHeight: 70,
  width: 795,
  earlyEducationHeaderHeight: 80
};

export const ratioMap = {
  secondStep: 0.70,
  exercisePreview: 0.87,
  testPreview: 0.81
};

export const DIRECTIONS = Object.freeze({ UP: 'UP', DOWN: 'DOWN', NONE: 'NONE' });

export enum GTLinkTypes {
  NO_SCORING,
  NORMAL,
  ANSWER_KEY,
  ECO,
  ZIP,
  ANSWER_CARD,
  SPE
}

export enum GtPageTypes {
  TEST_DATABASE,
  EXERCISES_DATABASE,
}

export enum Active {
  DRAFT,
  PUBLISHED,
  READY_TO_BE_PUBLISHED
}

export type ActivityStatus = 0 | 1 | 2;

export type ChildNameFiltr = 'category' | 'skill';

export type GroupNameFiltr = 'level' | 'type' | 'source' | 'range' | 'published' | 'active' | 'time';

export enum NameFiltr {
  LEVEL = 'level',
  TYPE = 'type',
  SOURCE = 'source',
  RANGE = 'range',
  PUBLISHED = 'published',
  ACTIVE = 'active',
  TIME = 'time',
  IN_TEST = 'inTest',
}

export enum AnswerFieldType {
  GRID = 'grid',
  LINES = 'lines',
  BLANK = 'blank',
  THREE_LINE = 'three-line',
  MOVE_UP = '<',
  MOVE_DOWN = '>',
  DELETE = '~'
}

// TODO: To remove
export const previewHeaderHeight = 100;
export const previewHeaderHeightEarlyEducation = 130;

export enum SentryEnv {
  PRD = 'PRD',
  PRE = 'PRE',
  TST = 'TST',
  INT = 'INT',
  DEV = 'DEV'
}

export const AnswerCardLimits = {
  cellsOnLeftSide: 25,
  cellsOfTeacherSection: 22
};

export const SppName = 'reforma-2017-szkoly-ponadpodstawowe';

export const WorkCardsName = 'Karty pracy';
export const TestsAndExamsName = 'Testy i sprawdziany';
export const WorkCardName = 'Karta pracy';
