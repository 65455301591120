export enum RouteName {
  ARTICLE_PREVIEW = 'article-preview',
  AUTHORIZATION = 'authorization',
  AUTHORIZATION_HASH = 'authorization-hash',
  COOKIES_POLICY = 'cookies-policy',
  EXERCISE_COMPOSER = 'exercise-composer',
  EXERCISE_DATABASE = 'exercises',
  EXERCISE_EDITOR = 'exercise-editor',
  EXERCISE_PREVIEW = 'exercise-preview',
  HELP = 'help',
  HOME = 'home',
  LOGIN = 'login',
  LOGOUT = 'logout',
  MUL_ARTICLE = 'mul-article',
  REGISTER = 'register',
  TEST_COMPOSER = 'composer',
  TEST_DATABASE = 'tests',
  TEST_EDITOR = 'test-editor',
  PREVIEW = 'preview'
}

export enum RoutePath {
  ARTICLE_PREVIEW = '/podglad-artykulu',
  COOKIES_POLICY = '/polityka-cookies',
  EXERCISE_COMPOSER = '/nowe',
  EXERCISE_DATABASE = '/lista',
  EXERCISE_EDITOR = '/edytuj',
  GENERATOR = '/',
  HELP = '/pomoc',
  HOME = '/',
  LOGIN = '/login',
  MUL_ARTICLE = '/artykuly',
  PREVIEW = '/preview',
  REGISTER = '/zarejestruj',
  TEST_COMPOSER = '/nowy-test',
  TEST_DATABASE = '/lista-testow',
  TEST_EDITOR = '/edytuj-test',
  TEST_PREVIEW = '/podglad-testu',
}
