import {
  DIRECTIONS,
  pageDimensions
} from '@/config';
import { Page, TestVariant } from '@/models';
import { cloneNoRef } from '@/helpers/index';
import { TestReorderPayload } from '@/models/tests';

export const swap = (arr: any, firstElementIndex: number, secondElementIndex: number) => {
  if (arr.length === 1) return arr;
  arr.splice(secondElementIndex, 1, arr.splice(firstElementIndex, 1, arr[secondElementIndex])[0]);
  return arr;
};

export const arrayToPages = (flatPages: Page[], earlySchoolEducation: boolean | null): Page[][] => {
  const reorderedPages: Page[][] = [[]];
  let currentPageHeight = earlySchoolEducation ? pageDimensions.earlyEducationHeaderHeight : pageDimensions.pageHeaderHeight;

  flatPages.forEach((exercise: Page) => {
    const exerciseHeight = exercise.printHeight;
    currentPageHeight += exerciseHeight;
    if (currentPageHeight > pageDimensions.pageContentHeight) {
      reorderedPages.push([]);
      currentPageHeight = exerciseHeight;
    }

    reorderedPages[reorderedPages.length - 1].push(exercise as never);
  });
  return reorderedPages;
};

export const pagesToArray = (arr: any) => [].concat(...arr);

export const order = (pages: Page[][], eIndex: any, ePageIndex: any, direction: any, earlySchoolEducation: boolean | null, doSwap = false, isRedactorExam: boolean = false) => {
  let flatPages = pagesToArray(pages);
  if (doSwap) {
    const currentExercise = pages[ePageIndex][eIndex];
    const exerciseIndex = isRedactorExam ? eIndex : flatPages.indexOf(currentExercise as never);
    if (direction === DIRECTIONS.UP) {
      flatPages = swap(flatPages, exerciseIndex, exerciseIndex - 1);
    }

    if (direction === DIRECTIONS.DOWN) {
      flatPages = swap(flatPages, exerciseIndex, exerciseIndex + 1);
    }
  }

  return arrayToPages(flatPages, earlySchoolEducation);
};

export const addExercise = (pages: any, exercise: any, earlySchoolEducation: boolean | null): Page[][] => {
  const flatPages = pagesToArray(pages);
  flatPages.push(exercise as never);
  return arrayToPages(flatPages, earlySchoolEducation);
};

export const removeExercise = (pages: any, exerciseId: any, earlySchoolEducation: boolean | null) => {
  const flatPages = pagesToArray(pages);
  // @ts-ignore
  const exerciseIndex = flatPages.indexOf(flatPages.find(exercise => exercise.id === exerciseId) as never);

  if (exerciseIndex > -1) {
    flatPages.splice(exerciseIndex, 1);
  }
  return arrayToPages(flatPages, earlySchoolEducation);
};

export const reorderTestExercises = (variants: TestVariant[], payload: TestReorderPayload, earlySchoolEducation: boolean | null, isRedactorExam: boolean) => {
  variants.forEach((variants: any) => {
    variants.pages = order(cloneNoRef(variants.pages), payload.exerciseIndex, payload.pageIndex, payload.direction, earlySchoolEducation, payload.doSwap, isRedactorExam);
  });
};
