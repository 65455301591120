import { RouteName, RoutePath } from '../models';

export default [
  {
    path: RoutePath.HOME,
    name: RouteName.HOME,
    component: () => import(/* webpackChunkName: "home" */ '@/views/Home/Home.vue')
  },
  {
    path: `${RoutePath.ARTICLE_PREVIEW}/:hash`,
    name: RouteName.ARTICLE_PREVIEW,
    component: () => import(/* webpackChunkName: "article-preview" */ '@/views/ArticlePreview/ArticlePreview.vue')
  },
  {
    path: `${RoutePath.HELP}`,
    name: RouteName.HELP,
    component: () => import(/* webpackChunkName: "help" */ '@/views/Help/Help.vue')
  },
  {
    path: `${RoutePath.COOKIES_POLICY}`,
    name: RouteName.COOKIES_POLICY,
    component: () => import(/* webpackChunkName: "cookies" */ '@/views/Cookies/Cookies.vue')
  },
  {
    path: `${RoutePath.MUL_ARTICLE}/:articleId`,
    name: RouteName.MUL_ARTICLE,
    component: () => import(/* webpackChunkName: "mulArticle" */ '@/views/MulArticle/MulArticle.vue')
  },
  {
    path: `${RoutePath.PREVIEW}`,
    name: RouteName.PREVIEW,
    component: () => import(/* webpackChunkName: "preview" */ '@/views/Preview/Preview.vue')
  },
  {
    path: `${RoutePath.GENERATOR}`,
    name: 'generator',
    component: () => import('@/views/Generator/Generator.vue'),
    children: [
      {
        path: `${RoutePath.TEST_DATABASE}/:categoryKey`,
        name: RouteName.TEST_DATABASE,
        component: () => import(/* webpackChunkName: "tests" */ '@/views/Generator/TestDatabase/TestDatabase.vue')
      },
      {
        path: `${RoutePath.TEST_COMPOSER}/:categoryKey/:testTypeId`,
        name: RouteName.TEST_COMPOSER,
        component: () => import(/* webpackChunkName: "composer" */ '@/views/Generator/TestComposer/TestComposer.vue')
      },
      {
        path: `${RoutePath.TEST_EDITOR}/:testId/:categoryKey/:testTypeId`,
        name: RouteName.TEST_EDITOR,
        component: () =>
          import(/* webpackChunkName: "test-editor" */ '@/views/Generator/TestComposer/TestComposer.vue')
      },
      {
        path: `/${RoutePath.EXERCISE_DATABASE}/:categoryKey`,
        name: RouteName.EXERCISE_DATABASE,
        component: () =>
          import(/* webpackChunkName: "exercises" */ '@/views/Generator/ExerciseDatabase/ExerciseDatabase.vue')
      },
      {
        path: `${RoutePath.EXERCISE_COMPOSER}/:categoryKey`,
        name: RouteName.EXERCISE_COMPOSER,
        component: () =>
          import(
            /* webpackChunkName: "exercise-composer" */ '@/views/Generator/ExerciseComposer/ExerciseComposer.vue'
          )
      },
      {
        path: `${RoutePath.EXERCISE_EDITOR}/:exerciseId/:categoryKey/:variantIndex`,
        name: RouteName.EXERCISE_EDITOR,
        component: () =>
          import(/* webpackChunkName: "exercise-editor" */ '@/views/Generator/ExerciseComposer/ExerciseComposer.vue')
      }
    ]
  }
];
