type SignatureIndex = {
  [key: string]: any;
}

// this method take source object and overwrite him with given destination object properties. If property is not present in destination object it will not be changed
export const objectMapper = <T>(source: T, destination: SignatureIndex): T => {
  Object.entries(source).forEach(([key]) => {
    // eslint-disable-next-line no-prototype-builtins
    if (destination.hasOwnProperty(key)) {
      (source as SignatureIndex)[key] = destination[key];
    }
  });
  return source;
};
