import { apiResourcesPath } from '@/api/config';
import { Source, SourceName } from '@/models/exercises';
import { pageDimensions, TestsAndExamsName, WorkCardsName } from '@/config';
import { TestTypeIdEnum } from '@/models';
import { TranslateResult } from 'vue-i18n';

export const GroupMap = new Map<number, string>([
  [0, 'A'],
  [1, 'B'],
  [2, 'C'],
  [3, 'D'],
  [4, 'E'],
  [5, 'F'],
  [6, 'G'],
  [7, 'H'],
  [8, 'I'],
  [9, 'J']
]);

export const getMaxPrintHeight = (printHeight: number[]) => Math.max.apply(Math, printHeight);

export const cloneNoRef = (object: any) => JSON.parse(JSON.stringify(object));

export const isInViewPort = (elem: any): boolean => {
  if (elem) {
    const distance = elem.getBoundingClientRect();
    return (
      distance.top >= 0 &&
      distance.left >= 0 &&
      distance.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
      distance.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
  }
  return false;
};

export const helpVideo: any = {
  composer1: {
    src: '1_jak_przygotowac_test.mp4',
    type: 'video/mp4'
  },
  composer2: {
    src: '2_jak_wygenerowac_grupe.mp4',
    type: 'video/mp4'
  },
  tests: {
    src: '3_jak_korzystac_baza_testow.mp4',
    type: 'video/mp4'
  },
  exercises: {
    src: '4_jak_korzystac_baza_zadan.mp4',
    type: 'video/mp4'
  },
  exerciseComposer: {
    src: '5_jak_dodac_nowe_zadanie.mp4',
    type: 'video/mp4'
  }
};

export const getHelpVideo = (apiURL: string, src: string) => {
  const videoObject = helpVideo[src];
  return {
    src: `${apiURL}${apiResourcesPath}/video/${videoObject.src}`,
    type: videoObject.type
  };
};

export const testAuthor = (userId: number | null): SourceName => {
  return userId !== null ? Source.MY : Source.NOWA_ERA;
};

export const replaceWhiteSpace = (text: string): string => {
  return text.replace(/\s/g, '_');
};

export const getScalePageDimensions = (scaleFactor: number) => {
  return {
    height: scaleFactor * pageDimensions.a4PageHeight + 'px',
    width: scaleFactor * pageDimensions.width + 'px'
  };
};

export const fulfillWordsWithCommas = (wordsArr: string[]): string => {
  let words = '';
  wordsArr.forEach((item, index) => {
    words += index !== wordsArr.length - 1 ? item + ', ' : item;
  });
  return words;
};

export const getTestTypeName = (testTypeId: TestTypeIdEnum): TranslateResult => {
  if (testTypeId === TestTypeIdEnum.TEST) return TestsAndExamsName;
  if (testTypeId === TestTypeIdEnum.WORK_CARD) return WorkCardsName;
  return '';
};
export const isIOS = (): boolean => {
  return [
    'iPad Simulator',
    'iPhone Simulator',
    'iPod Simulator',
    'iPad',
    'iPhone',
    'iPod'
  ].includes(navigator.platform) ||
    // iPad on iOS 13 detection
    (navigator.userAgent.includes('Mac') && 'ontouchend' in document);
};
