import { ActionTree } from 'vuex';
import { LayoutModel, LayoutState, MenuModel } from '@/store/layout-store/types';
import { RootState } from '@/store/types';
import { SET_HEADER, SET_MENU, SET_ROLE } from '@/store/list';
import { MENU_LAYOUT, HEADER_LAYOUT } from '@/api/eob/endpoints';
import Vue from 'vue';

export const actions: ActionTree<LayoutState, RootState> = {
  async [SET_MENU] ({ rootState, commit }, categoryKey: string) {
    const menu: MenuModel = (await rootState.$api.get<MenuModel>(MENU_LAYOUT(categoryKey))).data;
    commit(SET_MENU, menu);
  },
  async [SET_HEADER] ({ rootState, commit }): Promise<LayoutModel> {
    const header: LayoutModel = (await rootState.$api.get<LayoutModel>(HEADER_LAYOUT)).data;
    commit(SET_HEADER, header);
    Vue.prototype.$piwik.push({
      event: 'login',
      externalId: header.user?.id ?? null,
      role: header.user?.role ?? null,
      standAlone: header.user?.firstLogin ?? null
    });
    if (header.user) commit('AppStore/' + SET_ROLE, header.user.role, { root: true });
    return header;
  }
};
