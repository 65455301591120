import { ActionTree } from 'vuex';
import { ExerciseState } from './types';
import { RootState } from '../types';
import {
  CHECK_COMMENTS_IN_CCOKIES,
  DELETE_EXERCISE,
  FILTER_EXERCISES,
  SET_EXERCISE_TO_PREVIEW,
  SET_EXERCISES_DETAILS,
  UPDATE_ANSWER_KEY,
  UPDATE_CACHED_EXERCISES,
  UPDATE_EXERCISE_VARIANT,
  UPDATE_TEST_AFTER_ANSWERS_MODIFICATION
} from '@/store/list';
import { DELETE_EXERCISE_URI, EXERCISES_LISTS_URI, EXERCISES_URI } from '@/api/generator/endpoints';
import { ExerciseAnswerField, ExercisesFilters, ExerciseVariant } from '@/models/exercises';
import { buildExerciseQueryParameters } from '@/api/generator/helpers';
import { find } from '@/helpers/array-manipulation';
import Vue from 'vue';
import { Exercise, ExerciseDetails, ExerciseStoreResponse } from '@/models';
import { AxiosResponse } from 'axios';
import { objectMapper } from '@/models/mappers';

declare let location: any;

export const actions: ActionTree<ExerciseState, RootState> = {
  async [FILTER_EXERCISES] ({ rootState, commit }, data: ExercisesFilters) {
    const _params = buildExerciseQueryParameters(data);
    const _exercises: AxiosResponse<ExerciseStoreResponse> = await rootState.$apiGt.get<any>(`${EXERCISES_URI}${_params}`);
    await commit(FILTER_EXERCISES, _exercises.data);
    await commit(SET_EXERCISES_DETAILS, _exercises.data.exercises);
  },

  async [SET_EXERCISES_DETAILS] ({ rootState, commit }, exercisesIds: number[]): Promise<void> {
    const exercisesData: Exercise[] = (await rootState.$apiGt.get<any[]>(EXERCISES_LISTS_URI + '/' + exercisesIds.join(','))).data;
    await commit(SET_EXERCISES_DETAILS, exercisesData);
  },

  async [SET_EXERCISE_TO_PREVIEW] ({ rootState, commit, state, dispatch }, payload: number) {
    let exerciseToPreview = find(state.cachedExercises, payload);
    if (!exerciseToPreview) {
      exerciseToPreview = find((await rootState.$apiGt.get<any>(EXERCISES_URI + '/' + payload)).data, payload);
    }

    dispatch(CHECK_COMMENTS_IN_CCOKIES, exerciseToPreview);
    commit(SET_EXERCISE_TO_PREVIEW, {
      exercise: exerciseToPreview,
      isOpened: true
    });
  },
  async [UPDATE_ANSWER_KEY] ({ commit }, payload: ExerciseAnswerField) {
    await commit(`TestStore/${UPDATE_TEST_AFTER_ANSWERS_MODIFICATION}`, payload, { root: true });
  },
  [UPDATE_EXERCISE_VARIANT] ({ commit }, payload) {
    commit(`TestStore/${UPDATE_EXERCISE_VARIANT}`, payload, { root: true });
  },
  async [DELETE_EXERCISE] ({ rootState }, exerciseId) {
    await rootState.$apiGt.delete(DELETE_EXERCISE_URI(exerciseId));
  },
  [UPDATE_CACHED_EXERCISES] ({ state }, payload: ExerciseDetails) {
    const exerciseIndexToUpdate = state.cachedExercises.findIndex(exercise => exercise.id === payload.id);
    if (exerciseIndexToUpdate >= 0) {
      state.cachedExercises[exerciseIndexToUpdate] = objectMapper(state.cachedExercises[exerciseIndexToUpdate], payload);
    }
  },
  [CHECK_COMMENTS_IN_CCOKIES] ({}, payload) {
    payload.variants.forEach((variant: ExerciseVariant) => {
      const savedComment = Vue.$cookies.get(`comment_${variant.id}`);
      variant.comment = savedComment || { comment: '', exerciseId: payload.id, variantId: variant.id, teacherAnswer: null, isSent: false };
    });
  }
};
