import { AnswersType } from '@/models/exercises';
import { Page } from '@/models';

export const numberOfCells = (exercises: Page[]): number => {
  return (
    exercises.length +
    numberOfCellsByType(exercises, AnswersType.BOOLEAN) +
    numberOfCellsByType(exercises, AnswersType.BUNDLES) +
    numberOfCellsByType(exercises, AnswersType.ASSIGNMENT) -
    exercises.filter(exercise =>
      [
        AnswersType.BOOLEAN,
        AnswersType.BUNDLES,
        AnswersType.ASSIGNMENT
      ].includes(exercise.answersType as AnswersType)
    ).length
  );
};

export const numberOfCellsByType = (exercises: Page[], type: AnswersType): number => {
  const prop = getAnswerProp(type);
  return exercises
    .map(exercise => (exercise.answersType === type && exercise[prop] ? exercise[prop]!.length : 0))
    .reduce((a, b) => a + b, 0);
};

export const getAnswerProp = (answersType: AnswersType): 'bundles' | 'additionalAnswers' | 'answers' => {
  switch (answersType) {
  case AnswersType.BUNDLES:
    return 'bundles';
  case AnswersType.ASSIGNMENT:
    return 'additionalAnswers';
  default:
    return 'answers';
  }
};

export const teacherSectionExercises = (exercises: Page[]) => {
  return exercises.filter(exercise => {
    return exercise.answersType ? [
      AnswersType.OPEN,
      AnswersType.OPEN_CLOSED,
      AnswersType.CLOSED
    ].includes(exercise.answersType) : exercise;
  });
};

export const isSecondStudentSection = (exercises: Page[], cellsOnOnePage: number): boolean => {
  return numberOfCells(exercises) > cellsOnOnePage;
};

export const exercisesInTable = (tableIndex: number, exercises: Page[], cellsOnOnePage: number) => {
  if (numberOfCells(exercises) > cellsOnOnePage) {
    let counter = 0;
    let splittedExercise = false;
    const firstTableExercises: Page[] = [];
    const secondTableExercises: Page[] = [];
    const exerciseAndAnswers: { exercise: Page; rows: number }[] = [];
    exercises.forEach(exercise => {
      const prop = getAnswerProp(exercise.answersType as AnswersType);
      const exerciseCopy = JSON.parse(JSON.stringify(exercise));
      [
        AnswersType.BOOLEAN,
        AnswersType.BUNDLES,
        AnswersType.ASSIGNMENT
      ].includes(exercise.answersType as AnswersType)
        ? exerciseAndAnswers.push({
          exercise: exerciseCopy,
          rows: exerciseCopy[prop]!.length
        })
        : exerciseAndAnswers.push({
          exercise: exerciseCopy,
          rows: 1
        });
    });
    exerciseAndAnswers.forEach(item => {
      const prop = getAnswerProp(item.exercise.answersType as AnswersType);
      counter += item.rows;
      if (counter <= cellsOnOnePage) {
        firstTableExercises.push(item.exercise);
      } else {
        if ([
          AnswersType.BOOLEAN,
          AnswersType.BUNDLES,
          AnswersType.ASSIGNMENT
        ].includes(item.exercise.answersType as AnswersType) && !splittedExercise && secondTableExercises.length === 0) {
          const diff = counter - cellsOnOnePage;
          const exerciseCopy = JSON.parse(JSON.stringify(item.exercise));
          const startIndex = item.exercise[prop]!.length - diff;
          item.exercise[prop]!.splice(startIndex, diff);
          exerciseCopy[prop] = exerciseCopy[prop]!.slice(startIndex);
          firstTableExercises.push(item.exercise);
          secondTableExercises.push(exerciseCopy);
          splittedExercise = true;
        } else {
          secondTableExercises.push(item.exercise);
        }
      }
    });
    return tableIndex === 0 ? firstTableExercises : secondTableExercises;
  }
  return exercises;
};
