import { RouteName, RoutePath } from '../models';
import { Route } from 'vue-router/types/router';

export default [
  {
    path: `${RoutePath.TEST_DATABASE}/:categoryKey(.*)`,
    redirect: ({ params }: Route) => {
      const categoryKey = params.categoryKey.replace(/\//g, ',');
      return {
        name: RouteName.TEST_DATABASE,
        params: { categoryKey }
      };
    }
  },
  {
    path: `${RoutePath.TEST_COMPOSER}/:categoryKey(.*)/:testTypeId`,
    redirect: ({ params }: Route) => {
      const categoryKey = params.categoryKey.replace(/\//g, ',');
      const testTypeId = params.testTypeId;
      return {
        name: RouteName.TEST_COMPOSER,
        params: { categoryKey, testTypeId }
      };
    }
  },
  {
    path: `${RoutePath.TEST_EDITOR}/:testId/:categoryKey(.*)/:testTypeId`,
    redirect: ({ params }: Route) => {
      const categoryKey = params.categoryKey.replace(/\//g, ',');
      return {
        name: RouteName.TEST_EDITOR,
        params: {
          testTypeId: params.testId,
          categoryKey
        }
      };
    }
  },
  {
    path: `${RoutePath.EXERCISE_DATABASE}/:categoryKey(.*)`,
    redirect: ({ params }: Route) => {
      const categoryKey = params.categoryKey.replace(/\//g, ',');
      return {
        name: RouteName.EXERCISE_DATABASE,
        params: { categoryKey }
      };
    }
  },
  {
    path: `${RoutePath.EXERCISE_COMPOSER}/:categoryKey(.*)`,
    redirect: ({ params }: Route) => {
      const categoryKey = params.categoryKey.replace(/\//g, ',');
      return {
        name: RouteName.EXERCISE_COMPOSER,
        params: { categoryKey }
      };
    }
  }
];
