import { SelectedFacet } from '@/models';
import { ExercisesFilters } from '@/models/exercises';

export interface QueryParameter {
  key: string;
  value: string | number;
}

export const prepareQueryParameters = (parameters: QueryParameter[]): string => {
  let queryString = '';
  if (parameters.length > 0) {
    const stringParams: string = parameters.map(p => `${p.key}=${p.value.toString()}`).join('&');
    queryString += `?${stringParams}`;
  }
  return queryString;
};

export const buildExerciseQueryParameters = (data: ExercisesFilters): string => {
  const params: QueryParameter[] = [
    { key: 'categoryKey', value: data.categoryKey },
    { key: 'p', value: data.p },
    { key: 'limit', value: data.limit },
    { key: 'sort', value: data.sort !== undefined ? data.sort : '' }
  ];
  if (data.searchQuery === null) {
    data.searchQuery = '';
  }
  if (data.searchQuery.length > 0) {
    params.push({ key: 'f:q', value: data.searchQuery });
  }

  if (data.activity) {
    params.push({ key: 'active', value: data.activity });
  }

  return `${prepareQueryParameters(params)}${facetsToUri(data.activeFacets)}`;
};

export const facetsToUri = (activeFacets: any) => {
  let URI = '';
  const _tmp = activeFacets.reduce((groups: any, item: SelectedFacet) => {
    const val = item.nameFiltr;
    groups[val] = groups[val] || [];
    groups[val].push(item.filterId);
    return groups;
  }, {});
  Object.keys(_tmp).forEach((key: any) => {
    // @ts-ignore
    URI += `&f:${key}=${_tmp[key].join(',')}`;
  });
  return URI;
};

export const wait = async (ms: number): Promise<void> => {
  return new Promise(resolve => setTimeout(resolve, ms));
};
