import { Active, ActivityStatus, AnswerFieldType } from '@/config';
import {
  AnswersType,
  Bundle, ExerciseAdditionalContent,
  ExerciseAnswer,
  ExerciseAnswerAdditional, ExerciseAttachment,
  ExerciseVariant,
  SourceName
} from '@/models/exercises';
import { ExercisePreviewTemplateEnum } from '@/views/Generator/ExerciseComposer/components/ExerciseContent/ExerciseTemplatePreviewModal/exercise-preview-template.enum';
import { EditorMode } from '@/views/Generator/ExerciseComposer/components/ExerciseContent/ExerciseContentEditor/models';
import { LocaleMessage } from 'vue-i18n';
import { RouteName } from '@/router/models';

export interface TestsResponseModel {
  activePage: number;
  numberOfPages: number;
  numberOfTests: number;
  tests: DatabaseTestModel[];
}

export interface DatabaseTestModel {
  active: number;
  canDelete: boolean;
  clonedId: any;
  comment: any;
  dateAccept: any;
  dateAdd: string;
  dateModify: string;
  earlySchoolEducation: boolean;
  exerciseCategoryLabel: string;
  exerciseCount: number;
  exerciseSkillLabel: string;
  file: string[];
  categoryId: number;
  generatorTestSourceId: number;
  generatorTestTypeId: number;
  hash: string;
  id: number;
  title: string;
  omr: number;
  published: number;
  scoring: number;
  time: number;
  userId: number | null;
  variantCount: number;
}

export interface TestHeader {
  date: boolean;
  grade: boolean;
  score: boolean;
  title: boolean;
  time: boolean;
  number: boolean;
}

export interface TestVariant {
  id?: number;
  pages: Page[][];
}

export interface TestAttachment {
  filename: string;
  urlDownload: string;
}

export interface TestModel {
  additionalOptions?: AdditionalOptions;
  answersTemplate?: ExercisePreviewTemplateEnum | null;
  attachments: TestAttachment[];
  author?: string;
  canDelete?: boolean;
  categoryId?: number | string;
  categoryKey?: string;
  clone?: boolean;
  cloneId?: number;
  dateModify?: string;
  earlySchoolEducation: boolean | null;
  exerciseCategoryLabel?: string;
  exerciseSkillLabel?: string;
  files?: string[];
  generatorTestTypeId: TestTypeIdEnum;
  hash?: string;
  header: TestHeader;
  id?: number;
  isAnswerSpace?: boolean;
  isDraft?: boolean;
  isRedactor?: boolean;
  isStepOne?: boolean;
  justificationWord?: null | string;
  leaveOneGroup?: boolean;
  published: boolean;
  time?: number
  title: string;
  totalScore?: number;
  userId?: number | null;
  variants: TestVariant[];
}

export enum TestTypeIdEnum {
  TASK = 1,
  BUNDLE_TASK,
  CARD,
  QUIZ,
  WORK_CARD,
  TEST
}

export interface RequestModel {
  baseUrl?: string;
  categoryId?: string;
  categoryKey?: string;
  id?: string;
  module?: string;
  pageId?: number;
  testId?: string;
  tokenJWT: string;
  typPage?: string;
}

export interface CachedPrintHeight {
  id: number | string;
  maxHeight?: number;
}

export interface SelectedFacet {
  filterId: number;
  nameFiltr: string;
}

export interface FacetItem {
  active: boolean;
  children?: FacetItem[] | GroupItem[];
  filters?: FacetItem[] | GroupItem[];
  id: number;
  name: string;
}

export interface GroupItem {
  children: FacetItem[];
  id: number;
  name: SourceName;
  nameFiltr: string;
}

export interface ExerciseInTest {
  categoryKey: string;
  categoryId: number;
  hash: string;
  name: string;
}

export interface ExerciseStoreResponse {
  attribute: ExerciseAttribute;
  earlySchoolEducation: boolean | null;
  exerciseCount: number;
  exercises: Exercise[];
}

export interface ExerciseAttribute {
  [key: string]: ExerciseAttributeElement;
}

export interface ExerciseAttributeElement {
  [key: number]: number
}

export interface ExerciseDetails {
  active: Active;
  additionalContent: ExerciseAdditionalContent;
  answersTemplate: ExercisePreviewTemplateEnum | null;
  answersType: AnswersType;
  attachments: ExerciseAttachment[];
  author: SourceName;
  canDelete: boolean;
  categoryId: string;
  categoryImageId: number;
  chapter: string;
  comment: null | string;
  copied: boolean;
  copyright: null | string;
  earlySchoolEducation: boolean;
  editor: EditorMode;
  englishTemplate: number | null;
  exerciseInTest: ExerciseInTest[];
  id: number;
  isAnswerSpace: boolean | null;
  isOwner: boolean;
  isPublic: boolean;
  level: null | string;
  newFlag: boolean;
  newFlagDays: number;
  requirements: null | string;
  score: number;
  section: string;
  skills: string[] | null;
  source: SourceName;
  time: number;
  title: null | string;
  userId: number;
  variants: ExerciseVariant[];
}

export interface Exercise {
  active: Active;
  additionalContent: ExerciseAdditionalContent;
  answersTemplate: ExercisePreviewTemplateEnum | null;
  answersType: AnswersType | null;
  attachments: ExerciseAttachment[];
  author: SourceName;
  canDelete: boolean;
  categoryId: number;
  categoryImageId: number;
  chapter: string;
  comment: null | string;
  copied: boolean;
  copyright: null | string;
  earlySchoolEducation: boolean;
  editor: EditorMode;
  englishTemplate: number | null;
  exerciseInTests: ExerciseInTest[];
  id: number;
  isAdmin: boolean;
  isAnswerSpace: boolean | null;
  isOwner: boolean;
  isPublic: boolean;
  level: null | string;
  newFlag: boolean;
  range: null | string;
  requirements: null | string;
  score: number;
  section: string;
  skills: string[] | null;
  title: null | string;
  time: number;
  type: string[];
  variants: ExerciseVariant[];
}

export interface Copyright {
  active: number;
  dateAdd?: any;
  dateModify: string;
  categoryId: number;
  id: number;
  text: string;
  textGenerator: string;
}

export interface CopyrightResponse {
  copyright: Partial<Copyright>;
}

export interface ExercisePreview {
  active: ActivityStatus;
  answersTemplate: ExercisePreviewTemplateEnum | null;
  answersType: AnswersType | null;
  attachments: any[] | boolean;
  author: SourceName;
  bundles: Bundle[] | null;
  canDelete: boolean;
  chapter: string;
  comment: string | null;
  content?: string;
  copyright: string | null;
  earlySchoolEducation: boolean;
  editUrl: string;
  editor: EditorMode;
  englishTemplate: number | null;
  exerciseInTests: ExerciseInTest[];
  id: number;
  isAnswerSpace?: boolean | null;
  isPublic: boolean;
  isOwner: boolean;
  level?: any;
  maxHeight?: number;
  newFlag: boolean;
  range?: string;
  requirements: string | null;
  score: number;
  section: string;
  selectedVariantIndex?: number;
  skills: string[] | null;
  time?: any;
  title: string | null;
  variants: ExerciseVariant[];
}

export interface ExercisePreViewConfig {
  exercise: Exercise;
  isOpened: boolean;
}

export type NotificationType = 'success' | 'error' | 'info' | 'warning';

export type NotificationConfig = string |
  {
    msg: string;
    type: NotificationType;
    permanent?: boolean;
    timeInMs?: number
  };

export interface Notification {
  id: number;
  message: string;
  type: NotificationType;
  timeInMs?: number;
}

export interface Page {
  additionalAnswers: ExerciseAnswerAdditional[] | null;
  answerFieldCount: number | null;
  answerFieldType: AnswerFieldType | null;
  answerKey: boolean;
  answers: ExerciseAnswer[] | null;
  answersTemplate?: ExercisePreviewTemplateEnum | null;
  answersType: AnswersType | null;
  attachments: ExerciseAttachment[];
  bundles: Bundle[] | null;
  comment: string | null;
  earlySchoolEducation?: boolean | null;
  editor: EditorMode;
  englishTemplate: number | null;
  id: number;
  isAnswerSpace?: boolean;
  isExerciseHeightCounted?: boolean;
  isOwner: boolean;
  justificationWord?: null | string;
  order: number;
  printHeight: number;
  printHeightSolution?: number;
  replay?: boolean;
  requirements: string | null;
  score: number;
  solution: string | null;
  task: string;
  time: number;
  variantId: number;
}

export interface VideoSource {
  src: string;
  type: string;
}

export interface StatsResponse {
  data: StatsResponseData;
}

export interface StatsResponseData {
  testCounter: number;
  testPgCounter: number;
  userTestCounter: number;
  userTestPgCounter: number;
}

export type StructureStatusName = 'new' | 'old' | null;

export enum StructureStatus {
  NEW = 'new',
  OLD = 'old'
}

export type TestTypeName = 'Zadanie maturalne' | 'Zadanie maturalne - wiązka' | 'Fiszka' | 'Quiz' | 'Karty pracy' | 'Testy i sprawdziany';

export interface TestType {
  id: TestTypeIdEnum;
  text: TestTypeName;
}

export interface AdditionalOptions {
  type: AdditionalOptionsType
}

export interface AdditionalOptionsType {
  [key: number]: string;
}

export interface SubMenuItem {
  name: LocaleMessage;
  iconName: string;
  route: RouteName;
  testTypeId?: TestTypeIdEnum;
  key: string;
}

export enum LoginStatus {
  VERIFICATION_IN_PROGRESS = 1,
  SUCCESS,
  NOT_VERIFIED
}
