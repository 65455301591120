import { Api } from '@/api';
import Vue from 'vue';
import { RequestModel } from '@/models';
import { CookieName } from '@/core/cookies';
import { apiPath, gtApiPath } from '@/api/config';
import store from '@/store';
import { basePath } from '@/config';

declare let request: RequestModel;

declare module 'vue/types/vue' {
  interface Vue {
    $api: Api;
    $apiGt: Api;
    $apiURL: string;
    $apiMne: Api;
    $apiMul: Api;
  }
}

const vueApi = (api: Api, apiGt: Api, apiURL: string, apiMne: Api, apiMul: Api) => ({
  install (vue: typeof Vue) {
    vue.prototype.$api = api;
    vue.prototype.$apiGt = apiGt;
    vue.prototype.$apiURL = apiURL;
    vue.prototype.$apiMne = apiMne;
    vue.prototype.$apiMul = apiMul;
  }
});

const vuexApi = (api: Api, apiGt: Api, apiURL: string, apiMne: Api, apiMul: Api) => {
  store.state.$api = api;
  store.state.$apiGt = apiGt;
  store.state.$apiURL = apiURL;
  store.state.$apiMne = apiMne;
  store.state.$apiMul = apiMul;
};

const interceptorsHandler = (instance: Api) => {
  instance.interceptors.request.use(
    config => {
      return config.data?.excludeToken ? { ...config, headers: { Authorization: '' } } : config;
    },
    error => {
      return Promise.reject(error);
    }
  );

  instance.interceptors.response.use(
    response => {
      return response;
    },
    error => {
      if (error.response?.status) {
        window.onbeforeunload = null;
        switch (error.response.status) {
        case 401:
          sessionStorage.setItem(CookieName.LOGIN_RETURN_PATH, window.location.href);
          window.location.pathname = '/logowanie';
          break;
        case 403:
          sessionStorage.setItem('access_status', 'denied');
          window.location.pathname = basePath;
          break;
        case 422:
          sessionStorage.setItem('access_status', 'denied');
          window.location.pathname = basePath;
          break;
        }
        return Promise.reject(error);
      }
    }
  );
};

export default async (apiURL: string, mneUrl: string, mulUrl: string) => {
  if (request && request.tokenJWT) Vue.$cookies.set(CookieName.USER_TOKEN, 'Bearer ' + request.tokenJWT);
  const token = Vue.$cookies.get(CookieName.USER_TOKEN);
  const headers = token ? { common: { Authorization: token } } : {};
  const baseURL = `${apiURL}${apiPath}`;
  const gtBaseURL = `${apiURL}${gtApiPath}`;
  const api = new Api({
    baseURL,
    headers
  });

  const apiMne = new Api({
    baseURL: mneUrl,
    headers
  });

  const apiMul = new Api({
    baseURL: mulUrl
  });

  interceptorsHandler(api);
  const apiGt = new Api({
    baseURL: gtBaseURL,
    headers
  });
  interceptorsHandler(apiGt);
  Vue.use(vueApi(api, apiGt, apiURL, apiMne, apiMul));
  vuexApi(api, apiGt, apiURL, apiMne, apiMul);
};
