export const ADD_TEST_GROUP = 'ADD_TEST_GROUP';
export const CHECK_COMMENTS_IN_CCOKIES = 'CHECK_COMMENTS_IN_CCOKIES';
export const CLOSE_PREVIEW_MODAL = 'CLOSE_PREVIEW_MODAL';
export const CREATE_NEW_TEST = 'CREATE_NEW_TEST';
export const DELETE_EXERCISE = 'DELETE_EXERCISE';
export const DELETE_TEST_ID = 'DELETE_TEST_ID';
export const DISPLAY_RECREATED = 'DISPLAY_RECREATED';
export const EXERCISES_ADD = 'EXERCISES_ADD';
export const EXERCISES_LIST = 'EXERCISES_LIST';
export const EXERCISES_ORDER = 'EXERCISES_ORDER';
export const EXERCISES_REMOVE = 'EXERCISES_REMOVE';
export const FETCH_TEST = 'FETCH_TEST';
export const FETCH_TEST_DATABASE = 'FETCH_TEST_DATABASE';
export const FETCH_TEST_PREVIEW = 'FETCH_TEST_PREVIEW';
export const FILTER_EXERCISES = 'FILTER_EXERCISES';
export const FOOTER = 'FOOTER';
export const GET_ANSWER_CARD_NOT_DOWNLOADABLE = 'GET_ANSWER_CARD_NOT_DOWNLOADABLE';
export const GET_ATTACHMENT_COUNTER = 'GET_ATTACHMENT_COUNTER';
export const GET_EXERCISE_COUNTER = 'GET_EXERCISE_COUNTER';
export const GET_EXERCISE_MAX_HEIGHT = 'GET_EXERCISE_MAX_HEIGHT';
export const GET_PAGE_COUNTER = 'GET_PAGE_COUNTER';
export const GET_SCORE_COUNTER = 'GET_SCORE_COUNTER';
export const GET_SELECTED_VARIANT = 'GET_SELECTED_VARIANT';
export const GET_TEST = 'CREATE_NEW_TEST';
export const GET_TEST_HEADER = 'GET_TEST_HEADER';
export const GET_TEST_STATE = 'GET_TEST_STATE';
export const GET_TEST_TITLE = 'GET_TEST_TITLE';
export const GET_TEST_TIME = 'GET_TEST_TIME';
export const HEADER = 'HEADER';
export const INCREMENT_NOTIFY_COUNTER = 'INCREMENT_NOTIFY_COUNTER';
export const IS_ADMIN = 'IS_ADMIN';
export const IS_DEBUG_MODE = 'IS_DEBUG_MODE';
export const IS_SUPER_USER = 'IS_SUPER_USER';
export const IS_SPP = 'IS_SPP';
export const RECALCULATE_TEST_VARIANTS = 'RECALCULATE_TEST_VARIANTS';
export const REMOVE_NOTIFICATION = 'REMOVE_NOTIFICATION';
export const RESET_CACHE = 'RESET_CACHE';
export const RESET_SELECTED_VARIANTS = 'RESET_SELECTED_VARIANTS';
export const SAVE_CHANGES_IN_TEST = 'SAVE_CHANGES_IN_TEST';
export const SAVE_NEW_TEST = 'SAVE_NEW_TEST';
export const SET_ANSWER_CARD_NOT_DOWNLOADABLE = 'SET_ANSWER_CARD_NOT_DOWNLOADABLE';
export const SET_CATEGORY_KEY = 'SET_CATEGORY_KEY';
export const SET_CURRENT_STEP = 'SET_CURRENT_STEP';
export const SET_DEFAULTS = 'SET_DEFAULTS';
export const SET_EXERCISES_DETAILS = 'SET_EXERCISES_DETAILS';
export const SET_LOGIN_STATUS = 'SET_LOGIN_STATUS';
export const SET_EXERCISE_TO_PREVIEW = 'SET_EXERCISE_TO_PREVIEW';
export const SET_EXERCISE_NUMBER = 'SET_EXERCISE_NUMBER';
export const SET_HEADER = 'SET_HEADER';
export const SET_MENU = 'SET_MENU';
export const SET_PAGE_ID = 'SET_PAGE_ID';
export const SET_ROLE = 'SET_ROLE';
export const SET_SELECTED_VARIANTS = 'SET_SELECTED_VARIANTS';
export const SET_FILTERS_ACTIVE = 'SET_FILTERS_ACTIVE';
export const SET_STRUCTURE_STATUS = 'SET_STRUCTURE_STATUS';
export const SET_TEST_DATABASE = 'SET_TEST_DATABASE';
export const SET_TEST_PREVIEW = 'SET_TEST_PREVIEW';
export const SET_TEST_VALIDATION_ACTIVE = 'SET_TEST_VALIDATION_ACTIVE';
export const TOGGLE_FACETS = 'TOGGLE_FACETS';
export const TOTAL_EXERCISES_FOUND = 'TOTAL_EXERCISES_FOUND';
export const UPDATE_ALL_TEST_VARIANTS = 'UPDATE_ALL_TEST_VARIANTS';
export const UPDATE_ANSWER_KEY = 'UPDATE_ANSWER_KEY';
export const UPDATE_CACHED_EXERCISES = 'UPDATE_CACHED_EXERCISES';
export const UPDATE_EXERCISE_VARIANT = 'UPDATE_EXERCISE_VARIANT';
export const UPDATE_TEST = 'UPDATE_TEST';
export const UPDATE_TEST_AFTER_ANSWERS_MODIFICATION = 'UPDATE_TEST_AFTER_ANSWERS_MODIFICATION';
export const UPDATE_TEST_AFTER_EXERCISE_ADD = 'UPDATE_TEST_AFTER_EXERCISE_ADD';
export const UPDATE_TEST_AFTER_EXERCISE_REMOVE = 'UPDATE_TEST_AFTER_EXERCISE_REMOVE';
export const UPDATE_TEST_AFTER_EXERCISE_REORDER = 'UPDATE_TEST_AFTER_EXERCISE_REORDER';
export const UPDATE_TEST_AFTER_EXERCISE_SAVE = 'UPDATE_TEST_AFTER_EXERCISE_SAVE';
export const UPDATE_TEST_BEFORE_PDF = 'UPDATE_TEST_BEFORE_PDF';
export const UPDATE_TEST_TITLE = 'UPDATE_TEST_TITLE';
export const UPDATE_TEST_TIME = 'UPDATE_TEST_TIME';
export const UPDATE_TOTAL_SCORE = 'UPDATE_TOTAL_SCORE';
export const USER_DATA = 'USER_DATA';
export const USER_NOTIFY = 'USER_NOTIFY';
export const VARIANTS_ORDER = 'VARIANTS_ORDER';
export const UPDATE_EXERCISE_PRINT_HEIGHT = 'UPDATE_EXERCISE_PRINT_HEIGHT';
