import { MutationTree } from 'vuex';
import { AuthState } from './types';

export const SET_USER_TOKEN = 'SET_USER_TOKEN';
export const SET_USER_HASH = 'SET_USER_HASH';
export const SET_IS_USER_LOGGED = 'SET_IS_USER_LOGGED';

export const mutations: MutationTree<AuthState> = {
  [SET_USER_TOKEN]: (state, token: string) => {
    state.userToken = token;
  },
  [SET_USER_HASH]: (state, hash: string) => {
    state.userHash = hash;
  },
  [SET_IS_USER_LOGGED]: (state, isUserLogged: boolean) => {
    state.isUserLogged = isUserLogged;
  }
};
