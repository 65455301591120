import { GetterTree } from 'vuex';
import { AppState } from './types';
import { RootState } from '../types';
import { IS_DEBUG_MODE, IS_SUPER_USER, IS_ADMIN, IS_SPP } from '@/store/list';
import { ROLES, SppName } from '@/config';

export const getters: GetterTree<AppState, RootState> = {
  [IS_SUPER_USER]: state => {
    return state.userRole.includes(ROLES.ADMIN) || state.userRole.includes(ROLES.REDACTOR_EXAM) || state.userRole.includes(ROLES.REDACTOR_DLU) || state.userRole.includes(ROLES.REDACTOR_GT);
  },
  [IS_ADMIN]: state => {
    return state.userRole.includes(ROLES.ADMIN);
  },
  [IS_DEBUG_MODE]: () => process.env.NODE_ENV !== 'production',
  [IS_SPP]: state => state.categoryKey.includes(SppName)
};
